.root {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
  }
  
  .media {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 0;
  }
  
  .text {
    width: 100%;
  
    &:first-child {
      margin-top: 0;
    }
  
    /* we style global elements only because they are generated by markdown processor */
    & a {
      color: var(--colorGrey700);
  
      &:hover {
        color: var(--marketplaceColor);
        text-decoration: none;
      }
    }
  
    & ol {
      margin-top: 0px;
      padding-left: 0px;
    }
  
    & li {
      font-size: 14px;
      color: var(--colorGrey700);
      padding: 0px;
      list-style-position: inside;
    }
  
    & p {
      padding: 0px;
    }
  }