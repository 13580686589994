.layout {
    min-height: 100vh;
    grid-template-rows: auto 1fr auto;
  }
  
  .topbar {
    position: sticky;
    top: 0;
    z-index: var(--zIndexTopbar);
  }
  
  .main {
    display: grid;
  }
  
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }